// Here you can add other styles

.c-sidebar{
  background: #27355a;
}


@media (hover: hover), not all
{
  .c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    color: #fff;
    background: #3c4b64;
  }
}

.bg-gradient-warning {
  background: #27355a !important;
  border-color: #272420 !important;
}
.cut-text { 
    text-overflow: ellipsis;
    overflow: hidden; 
    max-width: 300px; 
    white-space: nowrap;
  }

  .cut-text-modal{
    max-width: 270px; 
  }

  .cut-text-user-td{
    text-overflow: ellipsis;
    overflow: hidden; 
    max-width: 150px; 
    white-space: nowrap;
    min-width: 15%;
  }


  .postDetail-img{

    max-width: 100%;
  }

  .c-avatar-user{
    width: 175px;
    height: 175px;
    border-radius: 50em;
    max-height: 175px;
  }

  #photo-user-up{
    opacity: 0;
   position: absolute;
   z-index: -1;
  }

  .photo-user-label{
    cursor: pointer;
  }

  .display-post-header {
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.2;
}

.postDetail-img{
  max-height: 200px;
  height: 100%;
}

.post-card-header, .btn-brand{
  display: flex;
    align-items: center;
    justify-content: space-between;
}
.curationList-card{
  height: 380px;
}

.blank-curationList-card{
  height: 100%;
  display: flex;
    align-items: center;
    /* align-content: center; */
    justify-content: center;
}

.blank-category-card{
  height: 160px;
  display: flex;
    align-items: center;
    padding:55px;
    justify-content: center;
}

.curationList-cardHeader{
  height: 57%;
}

.curation-switch{
  display: flex;
    justify-content: flex-end;
}

.blank-list-img{
  cursor: pointer;
  max-width: 50%;
  height: auto;
  max-height: 100%;
}

.c-avatar-curation {
  width: 100%;
  height: auto;
  border-radius: 0;
}
.category-colors{
  background: #4f5d73 !important;
    background: linear-gradient(45deg, #468db7 0%, #4f4a8c 100%) !important;
    border-color: #1f1498 !important;
}


.user-profile-card{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width:100%;
}

.checkbox{
  margin-left: 0px !important;
}

.c-sidebar-brand{
  flex: 0 0 105px;
}

.c-sidebar-brand-img{
  max-height: 50px;
}

.c-sidebar {
  background: #3c4b64 !important;
}

.dynamic-form-input{
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.c-table-header{
  display: flex;
  align-items: center;
}

.cL-img{
  width: 100%;
  object-fit: cover;
}

.cL-img-container{
  width: 100%;
  max-width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ReactCrop__image {
  max-width: 100%;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  max-height: 300px;
}

.c-post-img{
  width: 100%;
  height: auto;
}